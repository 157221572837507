.media-viewer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: black;
}

.controls-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    pointer-events: none;
}

.controls-overlay header {
    height: 60px;
    padding: 10px 25px 10px 22px;
    display: flex;
    background-color: black;
    opacity: 60%;
    pointer-events: auto;
    /*flex-direction: column;*/
}

.hidden {
    /*display:none !important;*/
    visibility: hidden;
}

.controls-overlay header .back-arrow {
    text-align: left;
    justify-content: start;
}

.controls-overlay header .action-icons {
    text-align: right;
}

.controls-overlay header .title {
    flex-basis: 100%;
    text-align: center;
    color: white;
    line-height: 40px;
    /*font-size: 1.5em;*/
}

.asset-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.asset-container .left-arrow {
    pointer-events: auto;
    /*display: block;*/
    /*height: auto;*/
    /*margin: auto;*/
    top: 50%;
    left: 0px;
    transform: translatey(calc(-50% - 30px));

}

.asset-container .right-arrow {
    /*vertical-align: middle;*/
    pointer-events: auto;
    top: 50%;
    right: 10px;
    transform: translatey(calc(-50% - 30px));
}

.asset-container .asset {
    flex-basis: 100%;
    text-align: center;
}

/*.asset video {*/
/*    width: 100%;*/
/*}*/

.asset img {
    max-width: 100vw;
    max-height: 100vh;
}