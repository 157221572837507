.add-to-album-dialog {
    max-height: 90vh
}

.add-to-album-dialog header {
    height: 50px;
    display: flex;
    justify-content: space-between;

}

.add-to-album-dialog header h1 {
    margin-top: 0;
}

.album-short-listing-container {
    max-height: 70vh;
    min-height: 200px;
    overflow: auto;
}

.album-short-listing {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    border: 2px solid white;
}

.album-short-listing:hover {
    /*box-shadow: 120px 80px 40px 20px #0ff;*/
    border: 2px solid blue;
    cursor: pointer;
}

.album-short-listing-thumb {
    text-align: left;
    padding-right: 20px;
}

.album-short-listing-description {
    flex-grow: 1;
}

.stat-description {
    font-size: 0.8em;
    font-style: italic;
}