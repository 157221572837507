.back-header {
    height: 60px;
    display: flex;
    justify-items: center;
}

.action-header {
    background-color: #675761;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: 99;
}

.action-header-inner {
    color: white;
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding-top: 0px;
    margin: auto;
    gap: 10px;
}

.action-header button {
    color: white !important;
    flex-grow: 0;
    font-size: 0.9em;
}

.action-header .title {
    flex-grow: 1;
    padding-top: 15px;
    font-size: .9em;
}

header.main-header {
    background-color: #ebeae6;
}

a.menu-button {
    color: #73706a;
    font-size: 16px;
    margin: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 2px;
}

@media screen and (max-width: 470px) {
    a.menu-button span {
        display: none;
    }
}

a.menu-button:hover {
    text-decoration: none;
}

.action-header a {
    color: white;
}