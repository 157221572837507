.plum {
    color: #675761;
}

.amber {
    color: #e55f2f
}

body {
    font-family: "omnes-pro", sans-serif;
}

/* Some utility styles */
.p-0 {
    padding: 0px;
}

.p-1 {
    padding: 1rem;
}

.center-text {
    text-align: center;
}

html {
    font-family: "omnes-pro", sans-serif;
    width: 100vw;
    overflow-x: hidden;
}

h1 {
    color: #e55f2f;
}

h1 {
    width: 100%;
    -ms-flex-positive: 100;
    flex-grow: 100;
    font-size: 42px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1;
    margin-top: 0px;
    margin-bottom: 30px;
}

h2 {
    font-weight: 400;
    height: 30px;
    font-size: 27px;
    color: #675761;
    margin-bottom: 30px;
}

.page-title-text, h1 {
    color: #e55f2f;
    letter-spacing: .01em;
}

.page-container {
    padding: 0px;
}

.page-container header {
    padding: 12px 0px 8px 20px;
    /*background-color: #ebeae6;*/
    margin-right: 20px;
    margin-left: 0px;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 60px;
    z-index: 99;
}

/* Specific fixed-sizes to support various number of 147px columns columns */
@media screen and (min-width: 147px) {
    .thumbnail_container {
        width: 182px
    }

    .thumbnail {
        width: 100px !important;
        height: 100px !important;
    }
}

@media screen and (min-width: 306px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 306px
    }

    .thumbnail {
        width: 100px !important;
        height: 100px !important;
    }

}

@media screen and (min-width: 441px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 441px
    }

    .thumbnail {
        width: 145px !important;
        height: 145px !important;
    }
}

@media screen and (min-width: 588px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 588px
    }
}

@media screen and (min-width: 735px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 735px
    }
}

@media screen and (min-width: 882px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 882px;
    }
}

@media (min-width: 1029px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 1029px
    }
}

@media (min-width: 1176px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 1176px
    }
}

@media (min-width: 1323px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 1323px
    }
}

@media (min-width: 1470px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 1470px
    }
}

@media (min-width: 1617px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 1617px
    }
}

@media (min-width: 1764px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 1764px
    }
}

@media (min-width: 1911px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 1911px
    }
}

@media (min-width: 2058px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 2058px
    }
}

@media (min-width: 2205px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 2205px
    }
}

@media (min-width: 2352px) {
    .thumbnail_container {
        width: 2352px
    }
}

@media (min-width: 2499px) {
    .thumbnail_container, .back-header, .action-header-inner {
        width: 2499px
    }
}

.thumbnail_container {
    margin: 0 auto;
    margin-top: 70px;
    overflow: hidden;
}

/*.thumbnail_container {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(auto-fill, 10em);*/
/*    justify-content: space-between;*/
/*    grid-gap: 8px;*/
/*    margin: 10px 0px 0px 10px;*/
/*}*/

/*.thumbnail_container:after{*/
/*    content: "";*/
/*    flex: auto;*/
/*}*/
.thumbnail {
    display: inline-block;
    font-size: 18px; /* restore font size removed in container */
    list-style-type: none;
    width: 145px;
    height: 145px;
    /*line-height: 10em;*/
    margin: 1px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    position: relative;
}


.thumbnail img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    /*border-radius: 5px;*/
}

.thumbnail .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.thumb-info {
    /*height: 30px;*/
    /*margin: 0px 0px 3px 3px;*/
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    line-height: 0;
    width: 180px;
    display: flex;
    padding-left: 6px;
    padding-bottom: 6px;

}

.thumb-info span {
    color: white;
    text-overflow: ellipsis;
    font-size: 0.8em;
    /*background-color: rgba(216,215,214,.4);*/
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
}

.loading-spinner {
    width: 200px;
    height: 200px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
}

.page-container {
    margin: 95px 0px 0px 0px;
}

.share-thumb-container {
    /*margin: 95px 20px 20px 20px;*/
    margin: 0px 20px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 48px;
}

.shared-media-thumb {
    width: 255px;
    height: 240px;
    min-width: 255px;
    overflow: hidden;
    position: relative;
    display: inline-block;
}

.shared-media-thumb .thumb-description {
    height: 50px;
    position: absolute;
    bottom: 0;
}

.thumb-description .count-description {
    font-size: .911em;
    font-weight: 300;
    color: #73706a;
    padding-right: 3px;
    border: 1px solid transparent;
    position: relative;
    top: -4px;
}

.main-thumbnail {
    height: 190px;
    width: 190px;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
}

.sub-thumbnail-container {
    width: 63px;
    position: absolute;
    top: 0;
    right: 0;
}

.sub-thumbnail {
    height: 62px;
    width: 62px;
    margin-bottom: 2px;
    /*margin-left: 2px;*/
    background-size: cover;
}

@media (max-width: 360px) {
    .main-thumbnail {
        width: 160px;
        height: 160px;
    }

    .sub-thumbnail {
        width: 52px;
        height: 52px;
    }
}

@media (max-width: 620px) {
    .share-thumb-container {
        width: 355px;
        margin: auto;
    }

    .shared-media-thumb {
        width: 355px;
        height: 320px;
    }

    .album-item-count-description {
        font-size: 14px !important;
    }

    .sub-thumbnail {
        width: 87px;
        height: 87px;
    }

    .main-thumbnail {
        width: 265px;
        height: 265px;
    }

    .sub-thumbnail-container {
        width: 88px;
    }

    .share-thumb-container h1 {
        display: none;
        text-align: center;
        margin: 0px;
        color: #73706a;
        font-size: 1.2em;
        font-weight: 500;
        text-transform: none;
    }

    .page-container {
        margin: 80px 0px 0px 0px;
    }

    .main-header {
        /*display: none;*/
    }

}

.thumb-description {
    position: absolute;
    bottom: 50px;
}

.album-thumbnail-title {
    color: #675761;
    background-color: white;
    font-size: 15.75px;
    font-weight: 500;
}

.thumbnail-play-icon {
    margin-right: 5px;
}

.album-item-count-description {
    font-size: 12px;
    color: #73706a;
}

header.back-header {
    background-color: white;
    display: flex;
    align-items: center;
    height: 70px;
    margin: auto;
    /*margin-left: 0px;*/
    /*margin-right: 20px;*/
    /*padding-right: 20px;*/
    justify-content: space-between;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
}

/*header .menu-items{*/
/*    padding-top:10px;*/
/*    justify-items: end;*/
/*}*/
button.menu-button {
    height: 20px;
    background-color: transparent;
    color: #73706a;
    border: 0;
    box-shadow: none;
    width: auto;
    white-space: nowrap;
    font-size: 16px;
}

.menu-button svg {
    width: 25px;
    height: 25px;
}

.thumb-select {
    position: absolute;
    left: 3px;
    top: 5px;
    background: white;
    border: 1px solid black;
    border-radius: 0;
}

.thumb-select:checked {
    background-color: #e55f2f;
}

.media-thumbnail-image {
    width: 145px;
    height: 145px;
}